import React, { useEffect } from 'react';
import { Icon, Tooltip } from 'antd';

const createMarkup = text => ({ __html: text });

const Activity = param => {
	const { activity } = param;
	// console.log(param);
	useEffect(() => {}, []);

	const arrangeDay = day => {
		switch (day) {
			case 1:
				return '(一)';
			case 2:
				return '(二)';
			case 3:
				return '(三)';
			case 4:
				return '(四)';
			case 5:
				return '(五)';
			case 6:
				return '(六)';
			case 0:
				return '(日)';
			default:
				return '';
		}
	};

	const arrangeDate = (date, index) => {
		const newDate = new Date(date);
		const ammount = activity.date.length;
		return (
			<div className="activity__date-item">
				<div className="activity__date-month">
					{`${newDate.getMonth() + 1}月`}
				</div>
				<Tooltip title={date}>
					<div className="activity__date-group">
						<div className="activity__date-date">
							{newDate.getDate()}
						</div>
						<div className="activity__date-day">
							{arrangeDay(newDate.getDay())}
						</div>
					</div>
				</Tooltip>
				{index === ammount - 1 ? (
					''
				) : (
					<Icon style={{ width: '100%' }} type="caret-down" />
				)}
			</div>
		);
	};

	const arrangeDateItem = activity.date.map((item, index) => (
		<div key={index.toString()}>{arrangeDate(item, index)}</div>
	));

	return (
		<div className="activity">
			<div className="activity__date">{arrangeDateItem}</div>
			<div className="activity__content">
				<div className="activity__head">
					<h3>{activity.name}</h3>
				</div>
				<div
					className="activity__desc"
					dangerouslySetInnerHTML={createMarkup(activity.desc)}
				/>
			</div>
		</div>
	);
};

export default Activity;
