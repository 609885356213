import {
	FETCH_GOOGLE_API_SUCCESS,
	FETCH_GOOGLE_API_FAILED,
	FETCH_EVENT_LIST_SUCCESS,
	FETCH_EVENT_LIST_FAILED,
	FETCH_HOMEPAGE_SUCCESS,
	FETCH_HOMEPAGE_FAILED,
	FETCH_ABOUT_MENU_SUCCESS,
	FETCH_ABOUT_MENU_FAILED,
	FETCH_ABOUT_MEMBERS_SUCCESS,
	FETCH_ABOUT_MEMBERS_FAILED,
	FETCH_NEWS_SUCCESS,
	FETCH_NEWS_FAILED,
	FETCH_ABOUT_SUCCESS,
	FETCH_ABOUT_FAILED,
	FETCH_REGISTER_SUCCESS,
	FETCH_REGISTER_FAILED,
} from '../actions/getGoogleApi';

const INITIAL_STATE = {
	fetchApi: [],
	fetchEventList: [],
	fetchRegister: [],
	fetchHomePage: [],
	fetchAboutMembers: [],
	fetchAboutMenu: [],
	fetchNews: [],
	fetchAbout: [],
};

const getGoogleApi = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* API 列表 */
		case FETCH_GOOGLE_API_SUCCESS:
			return { ...state, fetchApi: action.payload };
		case FETCH_GOOGLE_API_FAILED:
			return { ...state, fetchApi: action.payload };

		/* 活動列表 */
		case FETCH_EVENT_LIST_SUCCESS:
			return { ...state, fetchEventList: action.payload };
		case FETCH_EVENT_LIST_FAILED:
			return { ...state, fetchEventList: action.payload };

		/* 首頁 */
		case FETCH_HOMEPAGE_SUCCESS:
			return { ...state, fetchHomePage: action.payload };
		case FETCH_HOMEPAGE_FAILED:
			return { ...state, fetchHomePage: action.payload };

		/* ABOUT 列表 */
		case FETCH_ABOUT_MENU_SUCCESS:
			return { ...state, fetchAboutMenu: action.payload };
		case FETCH_ABOUT_MENU_FAILED:
			return { ...state, fetchAboutMenu: action.payload };

		/* ABOUT 成員列表 */
		case FETCH_ABOUT_MEMBERS_SUCCESS:
			return { ...state, fetchAboutMembers: action.payload };
		case FETCH_ABOUT_MEMBERS_FAILED:
			return { ...state, fetchAboutMembers: action.payload };

		/* 最新消息 */
		case FETCH_NEWS_SUCCESS:
			return { ...state, fetchNews: action.payload };
		case FETCH_NEWS_FAILED:
			return { ...state, fetchNews: action.payload };

		/* 最新消息 */
		case FETCH_ABOUT_SUCCESS:
			return { ...state, fetchAbout: action.payload };
		case FETCH_ABOUT_FAILED:
			return { ...state, fetchAbout: action.payload };

		/* 報名方式 */
		case FETCH_REGISTER_SUCCESS:
			return { ...state, fetchRegister: action.payload };
		case FETCH_REGISTER_FAILED:
			return { ...state, fetchRegister: action.payload };

		default:
			return state;
	}
};
export default getGoogleApi;
