import $ from 'jquery';

$(() => {
	// if ie
	setTimeout(() => {
		const ua = navigator.userAgent;
		if (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) {
			$('.ifIE').fadeIn();
			$('.ifIE').append('<h3>建議使用Chrome瀏覽器開啟網頁</h3>');
			$('.page').fadeOut();
		} else {
			$('.ifIE').fadeOut();
			$('.page').fadeIn();
		}
	}, 1000);
});
