import React, { useEffect, useState } from 'react';
import { Anchor, Button, Modal } from 'antd';
import $ from 'jquery';
import Activity from '../activity/Activity';
import { eventList, register } from '../../../../data/source.json';

const { Link } = Anchor;

const createMarkup = text => ({ __html: text });

const switchCheck = check => {
	if (check === undefined) {
		return '';
	}
	return check;
};

const EventPage = param => {
	const [modalVisible, setVisible] = useState(false);
	const [modalData, setModalData] = useState({});
	const { info, sort, activity, path } = param;

	const arrangeRegister = Object.assign(
		{},
		...register.map(({ // eslint-disable-next-line no-shadow
			path, id, title, content, facebook, googleForm, googleFormLink, phone, mail, location }) => ({
			[path]: {
				id,
				title,
				desc: content,
				facebook,
				googleForm,
				googleFormLink,
				phone,
				mail,
				location,
				path,
			},
		}))
	);

	const arrangeDesc = () => {
		if (info.description.split('\\')[sort] !== undefined) {
			return info.description.split('\\')[sort];
		}
		return '';
	};

	const event = {
		ammount: info.childPath.split('\\').length,
		picture: info.childPicture.split('\\')[sort],
		name: info.event.split('\\')[sort],
		desc: arrangeDesc(),
		hash: info.childPath.split('\\').map((item, index) => (
			<Link
				key={index.toString()}
				href={`#${info.path}#${item}`}
				// title={item}
			/>
		)),
		next: {
			href: switchCheck(info.childPath.split('\\')[sort + 1]),
			title: switchCheck(info.event.split('\\')[sort + 1]),
		},
		prev: {
			href: switchCheck(info.childPath.split('\\')[sort - 1]),
			title: switchCheck(info.event.split('\\')[sort - 1]),
		},
	};

	// 檢查hash若為空值, 則填充當前類別第0個hash
	const currentSort = eventList
		.map(item => item.path)
		.indexOf(window.location.pathname.slice(1));

	useEffect(() => {
		// set default location
		if (window.location.hash !== '') {
			window.location.href = `${window.location.pathname}${
				window.location.hash
			}`;
		} else {
			window.location.href = `${window.location.pathname}#${
				eventList[currentSort].childPath.split('\\')[0]
			}`;
		}

		setTimeout(() => {
			const length = ((info.name && info.name.length) || 0) + 3;
			const hash = window.location.hash.slice(length);
			$(() => {
				const el = $(`#${hash || ''}`).offset();
				if (el) $('html, body').scrollTop(el.top);
			});
		}, 1500);
	}, []);

	const showModal = () => {
		if (arrangeRegister[param.path]) {
			setModalData(arrangeRegister[param.path]);
		} else {
			setModalData(arrangeRegister.all);
		}
		setVisible(true);
	};

	const handleOk = () => {
		setVisible(false);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const activityCheck = () => {
		if (activity.length > 0) {
			const activityItem = activity[sort][path].map((item, index) => (
				<Activity key={index.toString()} activity={item} />
			));
			return activityItem;
		}
		return <Activity key="0" activity={[]} />;
	};

	return (
		<div id={param.path} className="eventPage">
			{/* {event.prev.href === '' ? (
				''
			) : (
				<div className="page__switch eventPage__prev">
					<Anchor affix={false}>
						<Link
							href={`#${info.path}#${event.prev.href}`}
							title={event.prev.title}
						/>
					</Anchor>
				</div>
			)} */}
			<Modal
				title=""
				visible={modalVisible}
				onOk={() => handleOk()}
				onCancel={() => handleCancel()}
				okButtonProps={{ disabled: true }}
				cancelButtonProps={{ disabled: true }}
				footer={false}
			>
				<div className="register__info">
					<div className="register__info-title">
						{modalData.title}
					</div>
					<div className="register__info-desc">{modalData.desc}</div>
					<div className="register__info-card">
						<div className="register__info-card__item">
							<div className="register__info-card__item-ic">
								<img
									src={
										modalData.path === 'marriageCana' ||
										modalData.path === 'marriageNewlywed' ||
										modalData.path === 'singleSelect'
											? 'images/ic/svg/icon-line.svg'
											: 'images/ic/svg/icon-f.svg'
									}
									alt=""
								/>
							</div>
							<Button
								type="link"
								style={{ padding: '0px', textAlign: 'left' }}
								href="https://www.facebook.com/groups/113455488704730/?ref=share"
								target="_blank"
								block
							>
								<div
									className="register__info-card__item-content"
									dangerouslySetInnerHTML={createMarkup(
										modalData.facebook
									)}
								/>
							</Button>
						</div>
						{modalData.googleForm && (
							<div className="register__info-card__item">
								<div className="register__info-card__item-ic">
									<img
										src="images/ic/svg/icon-sheet.svg"
										alt=""
									/>
								</div>
								<Button
									type="link"
									style={{
										padding: '0px',
										textAlign: 'left',
									}}
									href={modalData.googleFormLink}
									target="_blank"
									block
								>
									<div
										className="register__info-card__item-content"
										dangerouslySetInnerHTML={createMarkup(
											modalData.googleForm
										)}
									/>
								</Button>
							</div>
						)}
						<div className="register__info-card__item">
							<div className="register__info-card__item-ic">
								<img
									src="images/ic/svg/icon-phone.svg"
									alt=""
								/>
							</div>
							<div
								className="register__info-card__item-content"
								dangerouslySetInnerHTML={createMarkup(
									modalData.phone
								)}
							/>
						</div>
						<div className="register__info-card__item">
							<div className="register__info-card__item-ic">
								<img src="images/ic/svg/icon-mail.svg" alt="" />
							</div>
							<div
								className="register__info-card__item-content"
								dangerouslySetInnerHTML={createMarkup(
									modalData.mail
								)}
							/>
						</div>
						<div className="register__info-card__item">
							<div className="register__info-card__item-ic">
								<img
									src="images/ic/svg/icon-location.svg"
									alt=""
								/>
							</div>
							<div
								className="register__info-card__item-content"
								dangerouslySetInnerHTML={createMarkup(
									modalData.location
								)}
							/>
						</div>
					</div>
				</div>
			</Modal>
			<div className="eventPage__wrap col-wrap">
				<div className="eventPage__head col-1">
					<div className="event__basic">
						<div className="event__anchor">
							<Anchor
								style={{ maxHeight: 'auto' }}
								affix={false}
								offsetTop={500}
							>
								{event.hash}
							</Anchor>
						</div>
						<div className="event__title">
							<div className="event__name">{event.name}</div>
							<div className="event__getMore">
								<Button
									type="primary"
									onClick={() => showModal()}
								>
									報名方式
								</Button>
							</div>
						</div>
					</div>

					<div className="event__img">
						{info.childPath.split('\\')[sort] === 'marriageCana' ? (
							<a
								href="https://www.lovecana.org.tw/index.php"
								rel="noopener noreferrer"
								target="_blank"
							>
								<img
									src={`images/ic/svg/${event.picture}`}
									alt=""
								/>
							</a>
						) : (
							<img
								src={`images/ic/svg/${event.picture}`}
								alt=""
							/>
						)}
					</div>
					{event.desc.length > 0 ? (
						<div
							className="event__desc"
							dangerouslySetInnerHTML={createMarkup(event.desc)}
						/>
					) : (
						''
					)}
				</div>
				<div className="eventPage__activity col-2">
					{activityCheck()}
				</div>
			</div>

			{/* {event.next.href === '' ? (
				''
			) : (
				<div className="page__switch eventPage__next">
					<Anchor affix={false}>
						<Link
							href={`#${info.path}#${event.next.href}`}
							title={event.next.title}
						/>
					</Anchor>
				</div>
			)} */}
		</div>
	);
};

export default EventPage;
