import React from 'react';
import EventPage from '../components/common/eventPage/EventPage';
import { eventList } from '../../data/source.json';
import events from '../../data/eventList.json';

import Upon from '../../images/ic/svg/background-event-upon.svg';
import Lower from '../../images/ic/svg/background-event-lower.svg';

const arrangeDesc = text => {
	const desc = text;
	return desc;
};

const arrangeDate = date => {
	if (date.length > 0) {
		if (date[0] === '') {
			return [];
		}
	}
	return date;
};

const CategoryPage = param => {
	const { path } = param.match;
	const id = path.slice(1);
	const pageSort = eventList.map(item => item.path).indexOf(id);

	const arrangeActivity = events[id].map(
		({ activity, childPath, content, event, date }, index) => ({
			name: activity,
			childPath,
			desc: arrangeDesc(content),
			date: (date && arrangeDate(date.split('\\'))) || [],
			event,
			id: index.toString(),
		})
	);
	const arrangeChildpath = arrangeActivity.map(item => item.childPath);
	const childPath = new Set(arrangeChildpath).toJSON();

	const eventItem = childPath.map(item => ({ [item]: [] }));

	arrangeActivity.map(item =>
		eventItem[childPath.indexOf(item.childPath)][item.childPath].push(item)
	);

	const eventContainerCheck = () => {
		if (childPath.length > 0) {
			const eventContainer = childPath.map((item, index) => (
				<EventPage
					key={index.toString()}
					info={eventList[pageSort]}
					activity={eventItem}
					path={item}
					sort={index}
				/>
			));
			return eventContainer;
		}
		return '';
	};

	return (
		<div className="page__container">
			<div className="page__container-bg">
				<div className="page__container-img__upon">
					<img src={Upon} alt="" />
				</div>
				<div className="page__container-img__lower">
					<img src={Lower} alt="" />
				</div>
			</div>
			<div className="page__container-main">
				<div className="page__container-page">
					{eventContainerCheck()}
				</div>
			</div>
		</div>
	);
};

export default CategoryPage;
