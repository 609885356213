import { FETCH_EVENT_SUCCESS, FETCH_EVENT_FAILED } from '../actions/getEvent';

const INITIAL_STATE = {
	fetchEvent: [],
};

const getEvent = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* 各項活動 */
		case FETCH_EVENT_SUCCESS:
			return { ...state, fetchEvent: action.payload };
		case FETCH_EVENT_FAILED:
			return { ...state, fetchEvent: action.payload };

		default:
			return state;
	}
};
export default getEvent;
