import axios from 'axios';
import services from '../config/services';

export const FETCH_GOOGLE_API_SUCCESS = 'FETCH_GOOGLE_API_SUCCESS';
export const FETCH_GOOGLE_API_FAILED = 'FETCH_HGOOGLE_API_FAILED';
export const FETCH_EVENT_LIST_SUCCESS = 'FETCH_EVENT_LIST_SUCCESS';
export const FETCH_EVENT_LIST_FAILED = 'FETCH_EVENT_LIST_FAILED';
export const FETCH_HOMEPAGE_SUCCESS = 'FETCH_HOMEPAGE_SUCCESS';
export const FETCH_HOMEPAGE_FAILED = 'FETCH_HOMEPAGE_FAILED';
export const FETCH_ABOUT_MENU_SUCCESS = 'FETCH_ABOUT_MENU_SUCCESS';
export const FETCH_ABOUT_MENU_FAILED = 'FETCH_ABOUT_MENU_FAILED';
export const FETCH_ABOUT_MEMBERS_SUCCESS = 'FETCH_ABOUT_MEMBERS_SUCCESS';
export const FETCH_ABOUT_MEMBERS_FAILED = 'FETCH_ABOUT_MEMBERS_FAILED';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILED = 'FETCH_NEWS_FAILED';
export const FETCH_ABOUT_SUCCESS = 'FETCH_ABOUT_SUCCESS';
export const FETCH_ABOUT_FAILED = 'FETCH_ABOUT_FAILED';
export const FETCH_REGISTER_SUCCESS = 'FETCH_REGISTER_SUCCESS';
export const FETCH_REGISTER_FAILED = 'FETCH_REGISTER_FAILED';

export const getGoogleApi = dispatch => {
	axios
		.get(services.getGoogleApi)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_GOOGLE_API_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_GOOGLE_API_FAILED,
				payload: error,
			});
		});
};

export const getEventList = dispatch => {
	dispatch({
		type: FETCH_EVENT_LIST_SUCCESS,
		payload: [],
	});
	// axios
	// 	.get(services.getEventList)
	// 	.then(response => {
	// 		if (response.data) {
	// 			dispatch({
	// 				type: FETCH_EVENT_LIST_SUCCESS,
	// 				payload: response.data.feed.entry,
	// 			});
	// 		}
	// 	})
	// 	.catch(error => {
	// 		dispatch({
	// 			type: FETCH_EVENT_LIST_FAILED,
	// 			payload: error,
	// 		});
	// 	});
};

export const getHomePage = dispatch => {
	axios
		.get(services.getHomePage)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_HOMEPAGE_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_HOMEPAGE_FAILED,
				payload: error,
			});
		});
};

export const getAboutMenu = dispatch => {
	axios
		.get(services.getAboutMenu)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_ABOUT_MENU_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_ABOUT_MENU_FAILED,
				payload: error,
			});
		});
};

export const getAboutMembers = dispatch => {
	axios
		.get(services.getAboutMembers)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_ABOUT_MEMBERS_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_ABOUT_MEMBERS_FAILED,
				payload: error,
			});
		});
};

export const getNews = dispatch => {
	axios
		.get(services.getNews)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_NEWS_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_NEWS_FAILED,
				payload: error,
			});
		});
};

export const getAbout = dispatch => {
	axios
		.get(services.getAbout)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_ABOUT_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_ABOUT_FAILED,
				payload: error,
			});
		});
};

export const getRegister = dispatch => {
	axios
		.get(services.getRegister)
		.then(response => {
			if (response.data) {
				dispatch({
					type: FETCH_REGISTER_SUCCESS,
					payload: response.data.feed.entry,
				});
			}
		})
		.catch(error => {
			dispatch({
				type: FETCH_REGISTER_FAILED,
				payload: error,
			});
		});
};
