import React from 'react';
import './priest.scss';

const Priest = () => (
	<div className="priest">
		<div className="priest__title">影片分享-歐義明神父在台傳教的一生</div>
		<div className="priest__frame">
			<iframe
				width="100%"
				height="300"
				src="https://www.youtube.com/embed/iv3ICK-YRK8"
				title="歐義明神父 追思影片"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>

		<div className="priest__title">影片分享-神父逝世周年彌撒精華片段</div>
		<div className="priest__frame">
			<iframe
				width="100%"
				height="300"
				src="https://www.youtube.com/embed/qneftUwQyTE"
				title="“神秘嘉賓”參加「歐義明神父逝世一週年追思會」"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	</div>
);

Priest.defaultProps = {};

Priest.propTypes = {};

export default Priest;
