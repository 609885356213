import React, { Suspense, useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { eventList, aboutMenuList } from '../data/source.json';
import services from './config/services';
import Header from './components/common/header/Header';

import HomeComponent from './containers/Home';
import AboutComponent from './containers/About';
import CategoryPageComponent from './containers/CategoryPage';

const LoadingMessage = () => 'Loading...';

const App = () => {
	useEffect(() => {
		const locationCheck = window.location.hash.indexOf('%23');
		if (locationCheck !== -1) {
			const reLocation = window.location.hash.replace('%23', '#');
			window.location.href = `/${reLocation}`;
		}

		// 暫時取代Redirect效果
		if (window.location.hash === '#/') {
			window.location.href = '/#/home#home';
		}
	}, []);

	const pages = [
		{
			path: '/home',
			name: 'home',
			component: HomeComponent,
		},
	];
	const pagesAbout = [
		{
			path: '/about',
			name: 'about',
			component: AboutComponent,
		},
	];

	const eventPages = eventList.map(list => ({
		...list,
		path: `/${list.path}`,
		name: list.path,
		component: CategoryPageComponent,
	}));

	return (
		<Router basename={services.getContextRoot}>
			<div className="app">
				<div className="ifIE" />
				<div className="page">
					<Header
						pages={pages}
						aboutMenuList={aboutMenuList}
						eventList={eventList}
					/>
					<Suspense fallack={<LoadingMessage />}>
						<Switch>
							{pages.map(page => (
								<Route key={page.path} {...page} />
							))}
							{pagesAbout.map(page => (
								<Route key={page.path} {...page} />
							))}
							{eventPages.map(page => (
								<Route key={page.path} {...page} />
							))}
						</Switch>
					</Suspense>
				</div>
			</div>
		</Router>
	);
};

export default App;
