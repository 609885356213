import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { NavLink } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';
// import { goToAnchor } from 'react-scrollable-anchor';
import { Menu, Dropdown, Icon, Drawer, Button } from 'antd';
import LogoCana from '../../../../images/ic/nav-logo-cana.svg';
import LogoFriend from '../../../../images/ic/nav-logo-friend.svg';

const { SubMenu } = Menu;

const Header = ({ pages, aboutMenuList, eventList }) => {
	const [drawerVisible, setDrawerVisible] = useState(false);

	const handleDrawer = () => {
		setDrawerVisible(true);
	};

	const drawerClose = () => {
		setDrawerVisible(false);
	};

	const handleMenuClick = () => {
		setDrawerVisible(false);
	};

	const eventMenu = (
		<Menu>
			{eventList.map(({ category, event, path, childPath }, index) => (
				<SubMenu key={index.toString()} title={category}>
					{event.split('\\').map((menuItem, i) => (
						<Menu.Item key={i.toString()}>
							<NavLink
								to={`/${path}#${childPath.split('\\')[i]}`}
							>
								{menuItem}
							</NavLink>
						</Menu.Item>
					))}
				</SubMenu>
			))}
		</Menu>
	);

	const aboutMenu = (
		<Menu>
			{aboutMenuList.map(({ path, childPath, category }, index) => (
				<Menu.Item key={index.toString()}>
					<NavLink to={`/${path}#${childPath}`}>{category}</NavLink>
				</Menu.Item>
			))}
		</Menu>
	);

	const mobileAboutMenu = aboutMenuList.map(
		({ path, childPath, category }, index) => (
			<Menu.Item key={index.toString()}>
				<NavLink to={`/${path}#${childPath}`}>{category}</NavLink>
			</Menu.Item>
		)
	);

	const mobileEventMenu = eventList.map(
		({ category, event, path, childPath }, index) => (
			<SubMenu key={index.toString()} title={category}>
				{event.split('\\').map((menuItem, i) => (
					<Menu.Item key={i.toString()}>
						<NavLink to={`/${path}#${childPath.split('\\')[i]}`}>
							{menuItem}
						</NavLink>
					</Menu.Item>
				))}
			</SubMenu>
		)
	);

	return (
		<div className="header">
			<div className="header__wrap">
				<div className="header__logo-wrap">
					<NavLink
						key="0"
						to={`${pages[0].path}#home`}
						className="header__logo"
					>
						<img className="LogoFriend" alt="" src={LogoFriend} />
					</NavLink>
					<a
						className="header__logo"
						href="https://www.lovecana.org.tw/index.php"
						rel="noopener noreferrer"
						target="_blank"
					>
						<img className="LogoCana" alt="" src={LogoCana} />
					</a>
				</div>

				<div className="header__dropdown" id="headerDropdown">
					<a
						href="https://www.facebook.com/groups/113455488704730/?ref=share"
						rel="noopener noreferrer"
						target="_blank"
						className="header__logo"
					>
						<div className="header__icon">
							<img src="images/ic/svg/icon-header-f.svg" alt="" />
						</div>
					</a>
					<NavLink to="about#donation" className="header__logo">
						<div className="header__icon">
							<img
								src="images/ic/svg/icon-header-donation.svg"
								alt=""
							/>
						</div>
					</NavLink>
					<Button
						className="mobileMenu"
						type="primary"
						onClick={() => handleDrawer()}
					>
						Menu
					</Button>
					{/* <div className="mobileHome">
						<NavLink to="home#home">
							<div>
								<Icon type="home" theme="filled" />
								首頁
							</div>
						</NavLink>
					</div> */}
					<Dropdown
						className="dropdown__item"
						overlay={aboutMenu}
						trigger={['click']}
						getPopupContainer={() =>
							document.getElementById('headerDropdown')
						}
					>
						<div>
							關於中心 <Icon type="caret-down" />
						</div>
					</Dropdown>
					<Dropdown
						className="dropdown__item"
						overlay={eventMenu}
						trigger={['click']}
						getPopupContainer={() =>
							document.getElementById('headerDropdown')
						}
					>
						<div>
							中心活動 <Icon type="caret-down" />
						</div>
					</Dropdown>
					<NavLink to={`${pages[0].path}#news`}>
						<Button type="link" style={{ fontSize: '16px' }}>
							活動快訊
						</Button>
					</NavLink>
				</div>
			</div>
			<Drawer
				title={
					<div className="header__logo-wrap">
						<NavLink
							key="0"
							to={`${pages[0].path}#home`}
							className="header__logo"
							onClick={() => handleMenuClick()}
						>
							<img
								className="LogoFriend"
								alt=""
								src={LogoFriend}
							/>
						</NavLink>
						<a
							className="header__logo"
							href="https://www.lovecana.org.tw/index.php"
							rel="noopener noreferrer"
							target="_blank"
							onClick={() => handleMenuClick()}
						>
							<img className="LogoCana" alt="" src={LogoCana} />
						</a>
					</div>
				}
				placement="left"
				closable={false}
				onClose={() => drawerClose()}
				visible={drawerVisible}
				width="260"
			>
				<Menu
					theme="dark"
					onClick={e => handleMenuClick(e)}
					style={{ width: '100%' }}
					defaultOpenKeys={['sub1']}
					mode="inline"
				>
					<SubMenu key="sub1" title={<span>關於中心</span>}>
						{mobileAboutMenu}
					</SubMenu>
					<SubMenu key="sub2" title={<span>中心活動</span>}>
						{mobileEventMenu}
					</SubMenu>
					<Menu.Item>
						<NavLink to="about#donation">奉獻支持</NavLink>
					</Menu.Item>
					<Menu.Item>
						<a
							href="https://www.facebook.com/groups/113455488704730/?ref=share"
							rel="noopener noreferrer"
							target="_blank"
							className="header__logo"
						>
							Facebook粉絲團
						</a>
					</Menu.Item>
				</Menu>
			</Drawer>
		</div>
	);
};

Header.defaultProps = {
	pages: [],
	aboutMenuList: [],
	eventList: [],
};

Header.propTypes = {
	pages: PropTypes.arrayOf(PropTypes.any),
	aboutMenuList: PropTypes.arrayOf(PropTypes.any),
	eventList: PropTypes.arrayOf(PropTypes.any),
};

export default Header;
