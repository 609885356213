/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { Button, Tooltip } from 'antd';
import $ from 'jquery';
import dataSource from '../../data/source.json';
import Upon from '../../images/ic/svg/background-index.svg';

// const { Link } = Anchor;

const createMarkup = text => ({ __html: text });

const Home = () => {
	const { home, eventlist, news } = Object.assign(
		{},
		...dataSource.homePage.map(({ path, name, title }) => ({
			[path]: { name, id: path, path, title },
		}))
	);

	useEffect(() => {
		const locale = 'home';
		const hashID = window.location.hash.slice(locale.length + 3);
		$(() => {
			setTimeout(() => {
				const el = $(`#${hashID}`);
				if (el) $('html, body').scrollTop(el.offset().top);
			}, 500);
		});
	}, []);

	const arrangeDay = day => {
		switch (day) {
			case 1:
				return '(一)';
			case 2:
				return '(二)';
			case 3:
				return '(三)';
			case 4:
				return '(四)';
			case 5:
				return '(五)';
			case 6:
				return '(六)';
			case 0:
				return '(日)';
			default:
				return '';
		}
	};

	const detectNaN = date => {
		if (Date.parse(date)) {
			return new Date(date);
		}
		return null;
	};
	const arrangeDate = date => {
		const newDate = detectNaN(date);
		return (
			<div className="activity__date-item">
				<div className="activity__date-month">
					{newDate && `${newDate.getMonth() + 1}月`}
				</div>
				<Tooltip title={date}>
					<div className="activity__date-group">
						<div className="activity__date-date">
							{newDate && newDate.getDate()}
						</div>
						<div className="activity__date-day">
							{arrangeDay(newDate && newDate.getDay())}
						</div>
					</div>
				</Tooltip>
			</div>
		);
	};

	const newsCard = dataSource.news.map(
		({ path, news: cardNews, activity, date }, index) => (
			<div
				key={index.toString()}
				className="home__container-page__news-card"
			>
				<div className="home__container-page__news-card__title">
					<NavLink to={path}>{cardNews}</NavLink>
				</div>
				<div className="home__container-page__news-card__content">
					{activity.split('\\').map((list, i) => (
						<div
							key={i.toString()}
							className="home__container-page__news-card__content-item"
						>
							<NavLink to={path}>
								<div className="activity__date">
									<div>
										{date &&
											arrangeDate(date.split('\\')[i], i)}
									</div>
								</div>
								<div className="home__container-page__news-card__content-item__name">
									{list}
								</div>
							</NavLink>
						</div>
					))}
				</div>
			</div>
		)
	);

	const eventCards = dataSource.eventList.map(
		({ path, childPath, picture, category, event, remark }, index) => (
			<div
				key={index.toString()}
				className="home__container-page__eventlist__card"
			>
				<div className="home__container-page__eventlist__card__head">
					<NavLink to={`${path}#${childPath.split('\\')[0]}`}>
						<div className="home__container-page__eventlist__card-ic">
							<img src={`images/ic/svg/${picture}`} alt="" />
						</div>
						<div className="home__container-page__eventlist__card-title">
							{category}
						</div>
					</NavLink>
				</div>
				<div className="home__container-page__eventlist__card-child__wrap">
					{event.split('\\').map((eventItem, i) => (
						<div
							key={i.toString()}
							className="home__container-page__eventlist__card-child"
						>
							<NavLink to={`${path}#${childPath.split('\\')[i]}`}>
								{eventItem}
							</NavLink>
						</div>
					))}
				</div>
				<div className="home__container-page__eventlist__card-remark">
					{remark}
				</div>
			</div>
		)
	);

	return (
		<div className="home">
			<div className="home__container">
				<div className="home__container-bg">
					<div
						className="home__container-img__upon"
						// style={{ backgroundImage: `url(${Upon}) norepeat` }}
					>
						<img src={Upon} alt="" />
					</div>
				</div>

				{/* #home#home */}
				<div className="home__container-main">
					<div id={home.path} className="eventPage">
						<div className="home__container-page__intro">
							<div className="home__container-page__intro-content">
								<div
									className="home__container-page__title"
									dangerouslySetInnerHTML={createMarkup(
										home && home.title
									)}
								/>
								<NavLink to="about#about">
									<Button type="primary">關於中心</Button>
								</NavLink>
								<NavLink to="marriage#marriageCana">
									<Button type="primary">關於愛在加納</Button>
								</NavLink>
							</div>
							<div className="home__container-page__intro-kv">
								<img
									src="images/ic/svg/img-index-jesus.svg"
									alt={home && home.name}
								/>
							</div>
						</div>
						{/* <div className="page__switch eventPage__next">
							<Anchor affix={false}>
								<Link
									href="#/home#eventlist"
									title={eventlist && eventlist.name}
								/>
							</Anchor>
						</div> */}
					</div>
				</div>

				{/* home#news */}
				<div className="home__container-main">
					<div id={news && news.path} className="eventPage">
						{/* <div className="page__switch eventPage__prev">
							<Anchor affix={false}>
								<Link
									href="#/home#eventlist"
									title={eventlist && eventlist.name}
								/>
							</Anchor>
						</div> */}
						<div className="home__container-page__news">
							<div className="home__container-page__title">
								<div className="home__container-page__news-title">
									{news.title}
									<Button
										type="link"
										href="https://drive.google.com/file/d/1WHhEWiRLZE50yHmtdSiEdOzqivBHlrBU/view?usp=sharing"
										target="_blank"
										className="home__container-page__news-title-btn"
									>
										2024 活動海報
									</Button>
									<Button
										type="link"
										href="https://drive.google.com/file/d/1P-SqEnSEVRZxNYdLEEEexEQbeM6WO6HW/view?usp=sharing"
										target="_blank"
										className="home__container-page__news-title-btn"
									>
										2024 青年靈修
									</Button>
									<Button
										type="link"
										href="https://drive.google.com/file/d/17HgL_iXseGoJr6mfFoW-vKOB9LEF0DIa/view?usp=sharing"
										target="_blank"
										className="home__container-page__news-title-btn"
									>
										愛在加納10周年音樂會
									</Button>
								</div>
							</div>
							<div className="home__container-page__news-content">
								<div className="home__container-page__news-card__wrap">
									{newsCard}
								</div>
								<div className="home__container-page__news-img">
									<img
										src="images/ic/svg/img-index-dancing.svg"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* home#eventlist */}
				<div className="home__container-main">
					<div id={eventlist && eventlist.path} className="eventPage">
						{/* <div className="page__switch eventPage__prev">
							<Anchor affix={false}>
								<Link
									href="#/home#home"
									title={home && home.name}
								/>
							</Anchor>
						</div> */}
						<div className="home__container-page__eventlist">
							<div
								className="home__container-page__title"
								dangerouslySetInnerHTML={createMarkup(
									eventlist && eventlist.title
								)}
							/>
							<div className="home__container-page__eventlist-box">
								{eventCards}
							</div>
						</div>
						{/* <div className="page__switch eventPage__next">
							<Anchor affix={false}>
								<Link
									href="#/home#news"
									title={news && news.name}
								/>
							</Anchor>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
