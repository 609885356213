import axios from 'axios';
// import services from '../config/services';

export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILED = 'FETCH_EVENT_FAILED';

const lastUrl = '=json';

export const getEvent = (dispatch, url) => {
	axios
		.get(`${url}${lastUrl}`)
		.then(response => {
			dispatch({
				type: FETCH_EVENT_SUCCESS,
				payload: response.data.feed.entry,
			});
		})
		.catch(error => {
			dispatch({
				type: FETCH_EVENT_FAILED,
				payload: error,
			});
		});
};
