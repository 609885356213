/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import $ from 'jquery';
import { Anchor, Button, Collapse } from 'antd';
import Priest from './priest/Priest';
import dataSource from '../../data/source.json';

import Upon from '../../images/ic/svg/background-about-upon.svg';
import Lower from '../../images/ic/svg/background-about-lower.svg';
import map from '../../images/map.png';
import './about.scss';

const { Link } = Anchor;

const { Panel } = Collapse;

const createMarkup = text => ({ __html: text });

const AboutPage = () => {
	const membersData = dataSource.aboutMembers.map((item, index) => ({
		...item,
		id: index,
	}));

	const members = membersData.map((item, index) => (
		<div key={index.toString()} className="about__members-item">
			<div className="about__members-item__avatar">
				<img src={item.picture} alt="" />
			</div>
			<div className="about__members-item__role">{item.role}</div>
			<div className="about__members-item__title">{item.name}</div>
		</div>
	));

	const {
		about,
		priest,
		donation,
		contact,
		association,
		languageZh,
		languageEn,
	} = Object.assign(
		{},
		...dataSource.aboutPage.map(
			({ path, name, desc, content, picture }) => ({
				[path]: { name, id: path, path, desc, content, picture },
			})
		)
	);

	const hash = dataSource.aboutPage.map(({ path }, index) => (
		<Link key={index.toString()} href={`#/about#${path}`} />
	));

	useEffect(() => {
		const locale = 'about';
		const hashID = window.location.hash.slice(locale.length + 3);

		$(() => {
			setTimeout(() => {
				const el = $(`#${hashID}`);
				$('html, body').scrollTop(el.offset().top);
			}, 500);
		});
	}, []);

	const contactData =
		about &&
		contact.desc.split('\\').map((item, index) => ({
			text: item,
			ic: contact.picture.split('\\')[index],
		}));

	const contactItem =
		contactData &&
		contactData.map((item, index) => (
			<div key={index.toString()} className="contact__card-item">
				<div className="contact__card-item__ic">
					<img src={`images/ic/svg/${item.ic}`} alt={item.text} />
				</div>
				<div
					className="contact__card-item__text"
					dangerouslySetInnerHTML={createMarkup(item.text)}
				/>
			</div>
		));

	const priestDownoadList = [
		{
			name: '關於歐神父',
			link:
				'https://drive.google.com/file/d/1ZsgRTJMZ8rsoB9x8U7S0XC7RIVfyNWZz/view?usp=sharing',
		},
		{
			name: '老朋友篇',
			link:
				'https://drive.google.com/file/d/1TbCclPbL2okwhfLRCyCdpl6W59w63pxT/view?usp=sharing',
		},
		{
			name: '青年篇',
			link:
				'https://drive.google.com/file/d/1DkTQiFAMr6ijb3HKjhwchvkV_afVdx2g/view?usp=sharing',
		},
		{
			name: '同事篇',
			link:
				'https://drive.google.com/file/d/1notddh_mi3iSoXHDIpzNTntqt-qZUzlY/view?usp=sharing',
		},
	];

	return (
		<div className="about__container">
			<div className="about__container-bg">
				<div className="about__container-img__upon">
					<img src={Upon} alt="" />
				</div>
				<div className="about__container-img__lower">
					<img src={Lower} alt="" />
				</div>
			</div>

			{/* about#about */}
			<div className="about__container-main">
				<div id={about && about.path} className="eventPage">
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{about && about.name}
									</div>
								</div>
							</div>
							<div
								className="event__desc"
								dangerouslySetInnerHTML={createMarkup(
									about && about.desc
								)}
							/>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="activity__content">
									<div className="activity__head">
										<h3>中心成員</h3>
									</div>
									<div className="about__members">
										{members}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="page__switch eventPage__next">
						<Anchor affix={false}>
							<Link
								href="#/about#donation"
								title={donation && donation.name}
							/>
						</Anchor>
					</div> */}
				</div>
			</div>

			{/* about#priest */}
			<div className="about__container-main">
				<div id={priest && priest.path} className="eventPage">
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{priest && priest.name}
									</div>
								</div>
							</div>
							<div className="event__img">
								<img
									src={`images/ic/svg/${priest &&
										priest.picture}`}
									alt=""
								/>
							</div>

							<div className="event__desc">
								<div className="priest__collapse">
									<Collapse defaultActiveKey={[]} ghost>
										<Panel
											header="歐義明神父（Rev. Alan T. Doyle, MM）生平事蹟簡述"
											key="0"
										>
											<div
												className="event__desc"
												dangerouslySetInnerHTML={createMarkup(
													priest && priest.desc
												)}
											/>
											<div
												className="event__desc"
												dangerouslySetInnerHTML={createMarkup(
													priest && priest.content
												)}
											/>
										</Panel>
									</Collapse>
								</div>
								<div className="about__priest__download">
									逝世週年追思文集
								</div>
								<div className="about__priest__download__list">
									{(priestDownoadList || []).map(
										({ name, link }) => (
											<div
												className="about__priest__download__list-item"
												key={name}
											>
												<Button
													type="link"
													onClick={() =>
														window.open(link)
													}
												>
													{name}
												</Button>
											</div>
										)
									)}
								</div>
							</div>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="activity__content">
									<div className="activity__desc">
										<Priest
											content={priest && priest.content}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* about#donation */}
			<div className="about__container-main">
				<div id={donation && donation.path} className="eventPage">
					{/* <div className="page__switch eventPage__prev">
						<Anchor affix={false}>
							<Link
								href="#/about#about"
								title={about && about.name}
							/>
						</Anchor>
					</div> */}
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{donation && donation.name}
									</div>
								</div>
							</div>
							<div className="event__img">
								<img
									src={`images/ic/svg/${donation &&
										donation.picture}`}
									alt=""
								/>
							</div>
							<div
								className="event__desc"
								dangerouslySetInnerHTML={createMarkup(
									donation && donation.desc
								)}
							/>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="activity__content">
									<div
										className="activity__desc"
										dangerouslySetInnerHTML={createMarkup(
											donation && donation.content
										)}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="page__switch eventPage__next">
						<Anchor affix={false}>
							<Link
								href="#/about#contact"
								title={contact && contact.name}
							/>
						</Anchor>
					</div> */}
				</div>
			</div>

			{/* about#contact */}
			<div className="about__container-main">
				<div id={contact && contact.path} className="eventPage">
					{/* <div className="page__switch eventPage__prev">
						<Anchor affix={false}>
							<Link
								href="#/about#donation"
								title={donation && donation.name}
							/>
						</Anchor>
					</div> */}
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{contact && contact.name}
									</div>
								</div>
							</div>
							<div className="event__img" />
							<div className="event__desc">
								<div className="contact__card">
									{contactItem}
								</div>
							</div>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="activity__content">
									<div className="activity__head">
										<a
											href="https://goo.gl/maps/ACfryWsdnzVYMFJj8"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img src={map} alt="" />
											<div className="activity__head-map">
												google map 連結
											</div>
										</a>
										{/* <GoogleMaps /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="page__switch eventPage__next">
						<Anchor affix={false}>
							<Link
								href="#/about#association"
								title={association && association.name}
							/>
						</Anchor>
					</div> */}
				</div>
			</div>

			{/* about#association */}
			<div className="about__container-main">
				<div id={association && association.path} className="eventPage">
					{/* <div className="page__switch eventPage__prev">
						<Anchor affix={false}>
							<Link
								href="#/about#contact"
								title={contact && contact.name}
							/>
						</Anchor>
					</div> */}
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{association && association.name}
									</div>
								</div>
							</div>
							<div className="event__img" />
							<div
								className="event__desc"
								dangerouslySetInnerHTML={createMarkup(
									association && association.desc
								)}
							/>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="association__bg">
									<img
										src={`images/ic/svg/${association &&
											association.picture}`}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* about#languageZh */}
			<div className="about__container-main">
				<div id={languageZh && languageZh.path} className="eventPage">
					{/* <div className="page__switch eventPage__prev">
						<Anchor affix={false}>
							<Link
								href="#/about#contact"
								title={contact && contact.name}
							/>
						</Anchor>
					</div> */}
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{languageZh && languageZh.name}
									</div>
									{/* <div className="event__getMore">
										<Button
											type="primary"
											onClick={() => showModal()}
										>
											報名方式
										</Button>
									</div> */}
								</div>
							</div>
							<div className="event__img" />
							<div
								className="event__desc"
								dangerouslySetInnerHTML={createMarkup(
									languageZh && languageZh.desc
								)}
							/>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="languageZh__bg">
									<img
										src={`images/ic/svg/${languageZh &&
											languageZh.picture}`}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* about#languageEn */}
			<div className="about__container-main">
				<div id={languageEn && languageEn.path} className="eventPage">
					{/* <div className="page__switch eventPage__prev">
						<Anchor affix={false}>
							<Link
								href="#/about#contact"
								title={contact && contact.name}
							/>
						</Anchor>
					</div> */}
					<div className="eventPage__wrap col-wrap">
						<div className="eventPage__head col-1">
							<div className="event__basic">
								<div className="event__anchor">
									<Anchor
										style={{ maxHeight: 'auto' }}
										affix={false}
										offsetTop={500}
									>
										{hash}
									</Anchor>
								</div>
								<div className="event__title">
									<div className="event__name">
										{languageEn && languageEn.name}
									</div>
									{/* <div className="event__getMore">
										<Button
											type="primary"
											onClick={() => showModal()}
										>
											報名方式
										</Button>
									</div> */}
								</div>
							</div>
							<div className="event__img" />
							<div
								className="event__desc"
								dangerouslySetInnerHTML={createMarkup(
									languageEn && languageEn.desc
								)}
							/>
						</div>
						<div className="eventPage__activity col-2">
							<div className="activity">
								<div className="languageEn__bg">
									<img
										src={`images/ic/svg/${languageEn &&
											languageEn.picture}`}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutPage;
