import webConfig from './base';

const { domain, contextRoot } = webConfig;
const lastUrl = '/public/values?alt=json';
/* Create  setLocale */
/* Read    getLocale */
/* Update  updateLocale */
/* Delete  deleteLocale */

export default {
	getContextRoot: contextRoot,
	getLocale: `${contextRoot}data/`,
	getLocaleEN: `${contextRoot}data/en.json`,
	domain: `${domain}`,
	apiTest: `${domain}/api/test`, // Your api url

	/* get googleDoc Api */
	getGoogleApi: `${domain}obioenm${lastUrl}`,
	getEventList: `${domain}ow2a717${lastUrl}`,
	getHomePage: `${domain}ov9ybrd${lastUrl}`,
	getAboutMenu: `${domain}oqc6k5s${lastUrl}`,
	getAboutMembers: `${domain}o60gk4d${lastUrl}`,
	getNews: `${domain}od6${lastUrl}`,
	getAbout: `${domain}ok4rfh3${lastUrl}`,
	getRegister: `${domain}o37yq73${lastUrl}`,
};
